<template>
  <main class="user-dashboard-bg">
    <DefaultContainer
      class="user-dashboard-container"
      :class="isProfilePage && 'user-dashboard-container--profile'"
    >
      <UserMenuSidebar />
      <slot />
    </DefaultContainer>
  </main>
</template>

<script setup lang="ts">
  import UserMenuSidebar from '~/components/common/UserMenuSidebar.vue'

  const { path } = useRoute()

  const isProfilePage = path === '/me/profile'
</script>

<style scoped lang="scss">
  .user-dashboard-bg {
    background: $base200;
  }

  .user-dashboard-container {
    display: grid;
    grid-template-columns: 320px 910px;
    gap: 30px;
    padding: 112px 110px 64px;
  }

  .user-dashboard-container--profile {
    grid-template-columns: 320px 490px;
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .user-dashboard-container {
      grid-template-columns: 1fr;
      gap: 0;
      padding: 75px 0 0;
    }

    .user-dashboard-container--profile {
      grid-template-columns: 1fr;
    }
  }
</style>
