<template>
  <section class="user-menu-sidebar">
    <Button
      v-for="menu in menus"
      :key="menu.path"
      is-borderless
      class="menu"
      :class="menu.path === path && 'menu--active'"
      @click="() => onClickMenu(menu.path)"
    >
      <component
        :is="menu.path === path ? menu.activeIcon : menu.icon"
        width="20"
        height="20"
      />
      <Text
        type="p2"
        weight="bold"
      >
        {{ menu.title }}
      </Text>
    </Button>
  </section>
</template>

<script setup lang="ts">
  import AdsOutlineIcon from '~/assets/icons/UserMenu/ads-outline.svg'
  import AdsSolidIcon from '~/assets/icons/UserMenu/ads-solid.svg'
  import HistoryGrayIcon from '~/assets/icons/UserMenu/history-gray.svg'
  import HistoryWhiteIcon from '~/assets/icons/UserMenu/history-white.svg'
  import LoveOutlineIcon from '~/assets/icons/UserMenu/love-outline.svg'
  import LoveSolidIcon from '~/assets/icons/UserMenu/love-solid.svg'
  import ProfileOutlineIcon from '~/assets/icons/UserMenu/profile-outline.svg'
  import ProfileSolidIcon from '~/assets/icons/UserMenu/profile-solid.svg'

  interface IMenu {
    activeIcon: string
    icon: string
    title: string
    path: string
  }

  const menus: IMenu[] = [
    {
      activeIcon: ProfileSolidIcon,
      icon: ProfileOutlineIcon,
      title: 'Profil',
      path: '/me/profile',
    },
    {
      activeIcon: AdsSolidIcon,
      icon: AdsOutlineIcon,
      title: 'Iklan Saya',
      path: '/me/ads',
    },
    {
      activeIcon: HistoryWhiteIcon,
      icon: HistoryGrayIcon,
      title: 'Riwayat Pencarian',
      path: '/me/history',
    },
    {
      activeIcon: LoveSolidIcon,
      icon: LoveOutlineIcon,
      title: 'Favorit',
      path: '/me/favorite',
    },
  ]

  const { path } = useRoute()
  const { push } = useRouter()

  const onClickMenu = (path: string) => {
    push(path)
  }
</script>

<style scoped lang="scss">
  .user-menu-sidebar {
    background: $base100;
    padding: 20px;
    height: fit-content;

    .menu {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 16px;
      color: $base800;
      background: $base200;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .menu--active {
      background: $blue500;
      color: $base100;
    }
  }

  @media screen and (max-width: ($smallDesktop + 'px')) {
    .user-menu-sidebar {
      display: flex;
      overflow: scroll;
      gap: 12px;
      padding: 12px;

      .menu {
        white-space: nowrap;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
